import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/CMSale'
import styled from '@emotion/styled';

import { ImitationsBlockStyle, ButtonStyle, Container } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'

import hero from '../../images/sales/spring-desktop-header.jpg';
import heroMobile from '../../images/sales/spring-mobile-header.jpg';
import detailImg from '../../images/icons/copper-icon-reusable.png';

const DesktopImg = styled.img`
    width: 100%;
    display: none;
    @media (min-width: ${breakpoints.m}px){
      display: block;
    }
`

const MobileImg = styled.img`
    width: 100%;
    display: block;
    @media (min-width: ${breakpoints.m}px){
      display: none;
    }
`

const DetailImg = styled.img`
    width: 75px;
    margin: auto;
    margin-bottom: 40px;
    display: block;
`

const ImageBlock = (props) => (
  <>
    <DesktopImg alt={props.title} src={props.img} />
    <MobileImg alt={props.title} src={props.mobileImg} />
  </>
)

const ImitationsBlock = () => (
  <ImitationsBlockStyle className='imitations-block' style={{textAlign: 'center'}}>
    <h3>Spring is in the air</h3>
    <h1>Reset & Renew</h1>
    <p>A new season is a great time to reset your mind and body. Find the products you need to feel refreshed and ready for summer with Copper Compression. Save 15% off sitewide with code BALANCE15</p>
    <ButtonStyle>
        <a href={"/sales/spring-equinox-sale?discount=BALANCE15"}>Click to apply code</a>
    </ButtonStyle>
  </ImitationsBlockStyle>
)


const SalePage = () => {
  return (
    <Container>
      <SEO title="Spring is in the air Sale" description="A new season is a great time to reset your mind and body. Find the products you need to feel refreshed and ready for summer with Copper Compression." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />
      <ImageBlock img={hero} mobileImg={heroMobile} title="Daylight Savings" />
      <ImitationsBlock />
      <DetailImg src={detailImg} />
      <SaleDetail tag='Save Sitewide' title='15% Off'/>
    </Container>
  )
}

export default SalePage
